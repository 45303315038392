import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import CBToolTip from '../../../components/CBToolTip';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import { PostData } from '../../../services/FetchData';
import { ProductContext } from '../../../store';
import { toast } from 'react-toastify';
import ContentEditable from "./ContentEditable"

const Dashboard = (props) => {
	const [copyText, setCopyText] = useState("Copy to clipboard"),
		[configLoader, setConfigLoader]	= useState(true),
		[typeSenseConfig, setTypeSenseConfig]	= useState({}),
		{
			currentWebsiteId
		} = useContext(ProductContext);

	useEffect(() => {
		if( currentWebsiteId ){
			getTypeSenseConfig()
		}
	}, [currentWebsiteId])

	const getTypeSenseConfig	= () => {
		setConfigLoader(true)
		PostData("ms1", "api/v1/credential/getWebsiteDetails", {websiteId: currentWebsiteId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setTypeSenseConfig(result.data.credential)
					console.log(result.data.credential)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setConfigLoader(false)
		})
	}

	const handleCopy = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			setCopyText("Copied");
			setTimeout(() => setCopyText("Copy to clipboard"), 2000); // Reset the copied state after 2 seconds
		}).catch(err => {
			console.error('Failed to copy text: ', err);
		});
	}

	return (
		<div>
			<div className="d-flex justify-content-between">
				<div className="w-100 d-flex flex-column align-items-start">
					<div className="w-100 pt-5 pb-2rem dotBanner">
						<div className="cbDisplayXlarge">
							<p className="mb-1">Welcome!</p>
							<p className="cbDisplaySmall cbSubduedText mb-3">Get Started with ConversionBox by using our resources below</p>
						</div>
					</div>
				</div>
			</div>
			<div className='mt-4'>
				<section id="apiKeyCards" className='cardDisplay cardDisplayBody lgMaxWidh550'>
					<div className='apiKeyCardHeaderWrap'>
						<div className='d-flex gap-2'>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
								<circle cx="7.5" cy="15.5" r="5.5"></circle>
								<path d="m21 2-9.6 9.6"></path>
								<path d="m15.5 7.5 3 3L22 7l-3-3"></path>
							</svg>
							<h3 className="cbDisplaySmall fw-semi-bold">Your API Keys</h3>
						</div>
						<p className="cbSubduedText mt-2">Use your unique keys below to begin implementing your ConversionBox search application.</p>
					</div>
					{configLoader ?
						<DetailSkeleton />
					:
						<>
							<div className="py-1 mt-2 copyTextInputWrap">
								<Label for="applicationHostInput" className="fw-bold">HOST</Label>
								<div className="relative maxW450">
									<div className="cbInput cbGroup cbInputMedium cbBorderGrey">
										<input className="mx-3 cbNoSearchInputDecoration" type="text" id="applicationHostInput" ariaInvalid="false" readOnly value={typeSenseConfig.host} />
									</div>
									<Button color='link' className="copyButton" ariaLabel="Copy to clipboard" onClick={() => handleCopy(typeSenseConfig.host)}>
										<CBToolTip item={{ placement: 'top', text: copyText }} id="hostTooltip">
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
												<path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
											</svg>
										</CBToolTip>
									</Button>
								</div>
							</div>
							<div className="py-1 mt-2 copyTextInputWrap">
								<Label for="applicationPortInput" className="fw-bold">PORT</Label>
								<div className="relative maxW450">
									<div className="cbInput cbGroup cbInputMedium cbBorderGrey">
										<input className="mx-3 cbNoSearchInputDecoration" type="text" id="applicationPortInput" ariaInvalid="false" readOnly value={typeSenseConfig.port} />
									</div>
									<Button color='link' className="copyButton" ariaLabel="Copy to clipboard" onClick={() => handleCopy(typeSenseConfig.port)}>
										<CBToolTip item={{ placement: 'top', text: copyText }} id="hostTooltip">
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
												<path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
											</svg>
										</CBToolTip>
									</Button>
								</div>
							</div>
							<div className="py-1 mt-2 copyTextInputWrap">
								<Label for="applicationProtocolInput" className="fw-bold">PROTOCOL</Label>
								<div className="relative maxW450">
									<div className="cbInput cbGroup cbInputMedium cbBorderGrey">
										<input className="mx-3 cbNoSearchInputDecoration" type="text" id="applicationProtocolInput" ariaInvalid="false" readOnly value={typeSenseConfig.protocol} />
									</div>
									<Button color='link' className="copyButton" ariaLabel="Copy to clipboard" onClick={() => handleCopy(typeSenseConfig.protocol)}>
										<CBToolTip item={{ placement: 'top', text: copyText }} id="hostTooltip">
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
												<path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
											</svg>
										</CBToolTip>
									</Button>
								</div>
							</div>
							<div className="py-1 mt-2 copyTextInputWrap">
								<Label for="applicationAdminAPIKeyInput" className="fw-bold">ADMIN API KEY</Label>
								<div className="relative maxW450">
									<div className="cbInput cbGroup cbInputMedium cbBorderGrey">
										<input className="mx-3 cbNoSearchInputDecoration" type="text" id="applicationAdminAPIKeyInput" ariaInvalid="false" readonly="" value={typeSenseConfig.adminAPIKey} />
									</div>
									<Button color='link' className="copyButton" ariaLabel="Copy to clipboard" onClick={() => handleCopy(typeSenseConfig.adminAPIKey)}>
										<CBToolTip item={{ placement: 'top', text: copyText }} id="hostTooltip">
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
												<path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
											</svg>
										</CBToolTip>
									</Button>
								</div>
							</div>
							<div className="py-1 mt-2 copyTextInputWrap">
								<Label for="applicationSearchAPIKeyInput" className="fw-bold">SEARCH API KEY</Label>
								<div className="relative maxW450">
									<div className="cbInput cbGroup cbInputMedium cbBorderGrey">
										<input className="mx-3 cbNoSearchInputDecoration" type="text" id="applicationSearchAPIKeyInput" ariaInvalid="false" readonly="" value={typeSenseConfig.searchAPIKey} />
									</div>
									<Button color='link' className="copyButton" ariaLabel="Copy to clipboard" onClick={() => handleCopy(typeSenseConfig.searchAPIKey)}>
										<CBToolTip item={{ placement: 'top', text: copyText }} id="hostTooltip">
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
												<path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
											</svg>
										</CBToolTip>
									</Button>
								</div>
							</div>
						</>
					}
					{/* <ContentEditable /> */}
				</section>
			</div>
		</div>
	);
}

export default Dashboard;